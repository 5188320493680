import api from '@/services/api';

const url = 'jobs';

const findJobs = () => api.get(url);

const createJobs = (param) => api.post(`${url}/create/`, param);

export default {
  findJobs, createJobs,
};
