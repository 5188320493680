<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="jobs"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Vagas Internas</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openDialog"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar Vaga</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click.prevent="openDialogCandidatesByJobId(item)"
              v-on="on"
            >
              mdi-account-group
            </v-icon>
          </template>
          <span>Candidatos Inscritos</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox
          :input-value="item.status"
          value
          disabled
          class="primary--text"
        />
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      persistent
    >
      <form-job
        v-if="dialog"
        :populate-with="jobToEdit"
        @close-dialog="closeDialog"
        @update-job-list="loadJobs"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogCandidatesByJobId"
      hide-overlay
      fullscreen
      persistent
    >
      <list-candidates-by-job-id
        v-if="dialogCandidatesByJobId"
        :populate-with="jobToEdit"
        @close-dialog="closeDialogCandidatesByJobId"
      />
    </v-dialog>
  </div>
</template>

<script>
import Jobs from '../../../services/jobs.service';

export default {
  name: 'JobsList',
  components: {
    'form-job': () => import('./Form'),
    'list-candidates-by-job-id': () => import('./CandidatesByJobId.vue'),
  },
  data() {
    return {
      headers: [
        {
          text: 'Ativo', align: 'start', sortable: false, value: 'active',
        },
        { text: 'Título', sortable: false, value: 'title' },
        { text: 'Autor', sortable: false, value: 'author' },
        { text: 'Data de Criação', value: 'createdAt', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      jobs: [],
      search: '',
      dialog: false,
      jobToEdit: {},
      dialogCandidatesByJobId: false,
    };
  },

  async created() {
    await this.loadJobs();
  },

  methods: {
    async loadJobs() {
      try {
        this.jobs = await Jobs.findJobs();
      } catch (error) {
        console.error('Erro ao carregar vagas internas:', error);
      }
    },

    closeDialog() {
      this.dialog = false;
      this.jobToEdit = {};
    },

    editItem(item) {
      this.jobToEdit = item;
      this.dialog = true;
    },

    openDialog() {
      this.dialog = true;
    },

    openDialogCandidatesByJobId(item) {
      this.dialogCandidatesByJobId = true;
      this.jobToEdit = item;
    },

    closeDialogCandidatesByJobId() {
      this.dialogCandidatesByJobId = false;
    },

  },
};
</script>
